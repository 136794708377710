import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { AppPageProps } from '../../types'
import { BlockList } from '../../components/BlockList'
import { LandPageQuery } from '../../../types/graphql-types'
import { Layout } from '../../components/Layout'
import { LoadingContext } from '../../contexts/LoadingContext'
import { OpticalImage } from '../../components/OpticalImage'
import { PhilosophyBlock } from '../../components/PhilosophyBlock'
import { PhilosophyItem } from '../../components/PhilosophyItem'
import { PhilosophyRelatedList } from '../../components/PhilosophyRelatedList'
import { SectionList } from '../../components/SectionList'
import { Statement } from '../../components/Statement'

export const query = graphql`
  query LandPage {
    heroMobile: file(
      relativePath: { eq: "images/philosophy-land-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroTablet: file(
      relativePath: { eq: "images/philosophy-land-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageOsuzuyama: file(
      relativePath: { eq: "images/philosophy-osuzuyama.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageWater: file(relativePath: { eq: "images/philosophy-water.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageWood: file(relativePath: { eq: "images/philosophy-wood.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageIngredients: file(
      relativePath: { eq: "images/philosophy-ingredients.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritMobile: file(
      relativePath: { eq: "images/philosophy-spirit-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritTablet: file(
      relativePath: { eq: "images/philosophy-spirit-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface LandPageProps extends AppPageProps {
  data: LandPageQuery
}

const LandPage: React.FC<LandPageProps> = ({
  pathContext: { langKey },
  data: {
    heroMobile,
    heroTablet,
    imageOsuzuyama,
    imageWater,
    imageWood,
    imageIngredients,
    spiritMobile,
    spiritTablet,
  },
}) => (
  <Layout
    langKey={langKey}
    pageTitle="土地"
    // eslint-disable-next-line max-len
    pageDescription="北に万吉山、南に矢筈岳や黒岳を従える宮崎県有数の山域、尾鈴山塊。その主稜が標高1405.2メートルの「尾鈴山」です。尾鈴山の年間降水量は3000ミリともいわれ、矢研の滝をはじめ、大小30あまりの瀑布（滝）を擁するほど水の豊かな山域として知られています。"
    titleSublabel="土地"
    titleLabel="Land"
  >
    <LoadingContext.Provider value="eager">
      <OpticalImage
        fluidMobile={heroMobile?.childImageSharp?.fluid as FluidObject}
        fluidTablet={heroTablet?.childImageSharp?.fluid as FluidObject}
        sizes="100vw"
      />
    </LoadingContext.Provider>
    <Statement>
      <p>
        尾鈴の尾根に雨が降る。 <br />
        霧のように音もなく。 <br />
        雨は森を濡らし、 <br />
        土を濡らし、 <br />
        地を深く透る。 <br />
        やがて湧き出るその水は <br />
        自然の理、 <br />
        人の技により <br />
        珠玉の酒となる。
      </p>
      <p>
        水の湧く山の奥深く <br />
        水と森の蒸留所 <br />
        尾鈴山蒸留所
      </p>
    </Statement>
    <SectionList>
      <BlockList>
        <PhilosophyBlock
          title="尾鈴山蒸留所"
          fluid={imageOsuzuyama?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            北に万吉山、南に矢筈岳や黒岳を従える宮崎県有数の山域、尾鈴山塊。その主稜が標高1405.2メートルの「尾鈴山」です。尾鈴山の年間降水量は3000ミリともいわれ、矢研の滝をはじめ、大小30あまりの瀑布（滝）を擁するほど水の豊かな山域として知られています。「酒造りは水に始まる」と古くから世界中で語り継がれてきたように、良質な水を探し求めてたどり着いたのがこの尾鈴山でした。黒木本店の別蔵として尾鈴山蒸留所が開設されたのが1998年。杉、ヒノキ、ブナ、カエデ、ミズナラなどの様々な木々に囲まれ、鳥のさえずりと清流のせせらぎがあたりを包む環境の中で、人と自然が一体となった酒造りがおこなわれています。ここでしか造れない。ここでしか造らない。それが尾鈴山蒸留所の一徹な蒸留酒造りです。
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="水"
          fluid={imageWater?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            年間3000ミリもの雨が降る尾鈴山。尾鈴山の森に降った雨は地中を幾層も浸透する過程で豊富なミネラルを蓄えます。尾鈴山によって濾過され、磨かれた水はきわめて硬度の低い軟水。喉元を柔らかく通り抜け、さらりと飲み干せるほどおいしい水です。尾鈴山蒸留所では川の上流、水源に近いところから水を引き、その水を仕込み水など蒸留酒造りのすべての工程に使用しています。
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="木"
          fluid={imageWood?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            木は適度に水分をコントロールしてくれるという特長を持ちます。そのため尾鈴山蒸留所では麹造りをする室や醪を造る桶はすべて木製を使用しています。中でも飫肥杉は江戸時代から船材として重宝されてきたように耐久性が高く、曲げやすいという特性を持つ宮崎県原産の杉。この飫肥杉を用いて嵩の深い桶をつくることで、微生物が活発に活動できる環境を整えることができるのです。
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="原料"
          fluid={imageIngredients?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            尾鈴山蒸留所の原料は自家農園「甦る大地の会」を始め、すべて九州で栽培したものを使用しています。自ら育て、自ら収穫した原料だからこそ、その特長を熟知し、十分に引き出すことができる。人と大地がひとつになった理想的な蒸留酒造りが尾鈴山蒸留所ではおこなわれているのです。
          </p>
        </PhilosophyBlock>
      </BlockList>
      <PhilosophyRelatedList>
        <PhilosophyItem
          to="/philosophy/spirit/"
          fluidMobile={spiritMobile?.childImageSharp?.fluid as FluidObject}
          fluidTablet={spiritTablet?.childImageSharp?.fluid as FluidObject}
          sublabel="精神"
          label="Spirit"
        />
      </PhilosophyRelatedList>
    </SectionList>
  </Layout>
)

export default LandPage
